<template>
  <div class="relative">
    <section class="min-h-screen flex items-center justify-center px-6 py-16">
      <div class="max-w-6xl mx-auto w-full grid lg:grid-cols-2 gap-8 items-center">
        <!-- Text Content (Kept the same) -->
        <div class="space-y-4">
          <h2 class="text-[#22c55e] font-mono text-lg fade-in">Hi, my name is</h2>
          <h1 class="text-5xl sm:text-6xl font-bold text-gray-200 fade-in" style="animation-delay: 100ms;">
            Nabil OUHMIDA
          </h1>
          <div class="relative">
            <h3 class="text-2xl sm:text-2xl font-bold text-[#22c55e] ">
              Junior Full Stack Developer
            </h3>
          </div>

          <p class="text-gray-400 mt-6 max-w-lg slide-up font-mono font-medium" style="animation-delay: 300ms;">
            I'm Nabil, a Junior Full Stack Developer with a focus on Vue.js and Laravel. I love creating
            responsive, intuitive web applications and enjoy turning ideas into functional solutions. Always eager to
            learn, grow, and tackle exciting development challenges!
          </p>
          <div class="flex gap-4 mt-8 fade-in" style="animation-delay: 400ms;">
            <!-- Mycv.pdf   -->
             <a href="/" download="Nabil OUHMIDA.pdf"
              class="inline-flex items-center px-6 py-3 rounded-lg bg-green-400/10 hover:bg-green-400/20 text-green-400 transition-colors duration-300">
              <svg class="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round">
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
              Resume
            </a>
            <a href="https://linkedin.com/in/nabil-ouhmida" target="_blank" rel="noopener noreferrer"
              class="inline-flex items-center px-6 py-3 rounded-lg bg-gray-800 hover:bg-[#0e76a8] text-gray-200 transition-colors duration-300">
              <svg class="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
              LinkedIn
            </a>
          </div>

        </div>

        <!-- Enhanced Animated Photo Container -->
        <div class="relative group fade-in" style="animation-delay: 400ms;">
          <div class="relative w-72 h-72 md:w-80 md:h-80 mx-auto">
            <!-- Animated Outer Ring -->
            <div class="absolute inset-0 animate-spin-slow">
              <svg class="w-full h-full" viewBox="0 0 300 300">
                <defs>
                  <linearGradient id="outerGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style="stop-color:#22c55e;stop-opacity:0.1" />
                    <stop offset="50%" style="stop-color:#22c55e;stop-opacity:0.4" />
                    <stop offset="100%" style="stop-color:#22c55e;stop-opacity:0.1" />
                  </linearGradient>
                  <filter id="glow">
                    <feGaussianBlur stdDeviation="3" result="coloredBlur" />
                    <feMerge>
                      <feMergeNode in="coloredBlur" />
                      <feMergeNode in="SourceGraphic" />
                    </feMerge>
                  </filter>
                </defs>
                <path d="M150,10 A140,140 0 0,1 150,290 A140,140 0 0,1 150,10" fill="none" stroke="url(#outerGradient)"
                  stroke-width="3" stroke-dasharray="40,10" filter="url(#glow)" />
              </svg>
            </div>

            <!-- Animated Middle Ring -->
            <div class="absolute inset-4 animate-spin-reverse">
              <svg class="w-full h-full" viewBox="0 0 300 300">
                <path d="M150,30 A120,120 0 0,0 150,270 A120,120 0 0,0 150,30" fill="none" stroke="#22c55e"
                  stroke-width="1" stroke-dasharray="20,20,5,5" opacity="0.2" />
              </svg>
            </div>

            <!-- Tech Dots with Enhanced Animation -->
            <div class="absolute inset-0">
              <div v-for="i in 12" :key="i" class="absolute w-2 h-2 rounded-full bg-green-400/40 backdrop-blur-sm"
                :style="{
                  left: `${50 + 45 * Math.cos(i * Math.PI / 6)}%`,
                  top: `${50 + 45 * Math.sin(i * Math.PI / 6)}%`,
                  animation: `pulse ${2 + i * 0.2}s infinite`,
                  filter: 'blur(1px)'
                }">
                <div class="absolute inset-0 bg-green-400/20 rounded-full animate-ping"></div>
              </div>
            </div>

            <!-- Enhanced Photo Frame -->
            <div class="absolute inset-8 flex items-center justify-center">
              <div class="relative w-full h-full rounded-full overflow-hidden group">
                <!-- Gradient Border -->
                <div
                  class="absolute inset-0 rounded-full p-1 bg-gradient-to-tr from-green-400/30 via-green-300/10 to-green-400/30 animate-gradient">
                </div>

                <!-- Inner Shadow -->
                <div class="absolute inset-0 rounded-full shadow-inner"></div>

                <!-- Profile Photo Container -->
                <div class="absolute inset-1 rounded-full overflow-hidden backdrop-blur-sm">
                  <div
                    class="absolute inset-0 bg-gradient-to-tr from-green-400/10 to-transparent opacity-50 group-hover:opacity-70 transition-opacity duration-300">
                  </div>
                  <img src="@/assets/images/nabil.jpg" alt="Profile photo"
                    class="w-full h-full object-cover transform transition-transform duration-300 " />
                </div>
              </div>
            </div>
          </div>
          <!-- Add the social links component -->
          <ProfileSocialLinks />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ProfileSocialLinks from './ProfileSocialLinks.vue'
export default {
  components: {
    ProfileSocialLinks
  }
}
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.6s ease-out forwards;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
    opacity: 0.3;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.6;
  }
}

.animate-spin-slow {
  animation: spin 20s linear infinite;
}

.animate-spin-reverse {
  animation: spin 15s linear infinite reverse;
}

.animate-gradient {
  animation: gradient 4s linear infinite;
  /* Reduced from 8s to 4s for faster animation */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes gradient {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.slide-up {
  opacity: 0;
  animation: slideUp 0.6s ease-out forwards;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {

  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
</style>